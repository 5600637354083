/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

/* title:#09509F;
hover:#05E790; */

/* --------------------------------------------------start of project--------------------------- */
body{
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}
/* --------------------------------header---------------------------------------------- */

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  height: 10vh;
  top: 0;
  width: 100%;
  margin-bottom: 2%;
  z-index: 999;
}

.logo img {
  width: 130px;
  height: 130px;
}
.logo h1{
  color:#052f5f;
  font-weight: bold;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 40px;
} 

.icons {
  display: flex;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #052f5f;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.icon:hover {
  background-color: #5A1627;
}

.menu {
  display: flex;
}
.menu li a:hover{
 color: #027147;;
}

ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border:  3px solid #052f5f;
  border-radius: 10px;
  height: 5vh;
}

li {
  margin: 10px;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: normal;
  font-family: Montserrat-Regular;
}
li:hover{
  color: #904753;
}

.link {
  text-decoration: none;
  color: #052f5f;
  font-weight: 550;
}

.active {
  color: #5A1627;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  font-size: 30px;
  color: black;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #555;
  margin: 3px 0;
  transition: all 0.3s ease;
}

.active .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.active .line:nth-child(2) {
  opacity: 0;
}

.active .line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -8px);
}

.dropdown {
  position: relative;
  color: #052f5f;
  font-weight: 550;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 10px;
  background-color: #052f5f;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 20vh;
  height: 14vh;
}

.dropdown-menu.active {
  display: block;
}

.dropdown-menu li {
  margin-bottom: 5px;
}

.dropdown-menu a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: normal;
  font-family: Montserrat-Regular;
}

.dropdown-menu a:hover {
  color: #05E790;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .menu {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #fff;
    width: 100%;
    display: none;
    transition: all 0.3s ease;
    height: 50vh;
    border-radius: 0;
    z-index: 999;
  }

  .hamburger.active + .menu {
    display: flex;
    padding-bottom: 15px;
    
  }

  .menu ul {
    flex-direction: column;
    padding: 10px;
    align-items: left;
    border: none;
  }

  .menu li {
    margin-right: 0;
    margin-top: 20px;
    color: #052f5f;
  }
}

@media (max-width: 1024px) {
  .logo {
    font-size: 20px;
  }

  .icon {
    font-size: 16px;
    margin-right: 15px;
  }
}

@media (max-width: 425px) {
  .logo {
    font-size: 16px;
  }
  .icons {
    display: none;
  }
  .dropdown-menu{
    height: 25vh;
    width: 70%;
  }

  .icon {
    font-size: 14px;
    margin-right: 10px;
  }

  .menu {
    top: 66px;
  }
}
/* ------------------------------------------------footer--------------------------------------- */
footer {
  background-color: #052f5f;
  padding: 20px;
  display: flex;
  justify-content: space-around ;
  flex-shrink: 0;
}

.footer-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #052f5f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}
.footer-icons :hover{
  background-color: #05E790;
}

.footer-text {
  text-align: center;
  margin-bottom: 20px;
}
.footer-text p{
  font-size: 18px;
  letter-spacing: normal;
  font-family: Montserrat-Regular;
  line-height: 1;
  color: #fff;
}

.footer-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 18px;
}

.footer-links li {
  margin: 0 10px;
}

@media (max-width: 768px) {
  footer {
    display: block;
  }
  .footer-icons {
   display: flex;
  }

  .footer-icon {
    margin-bottom: 10px;
  }
  .footer-links{
    display: flex;
    padding-top: 0px;
    justify-content: center;
  }
  .footer-links li{
    margin-top: 5px;
    
  }
}
/* ------------------------------------------Regsiter------------------------------------ */

.Register {
  display: flex;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 5%;
}

.Rinfo {
  width: 100%;
}

.Rinfo h1 {
  text-align: center;
  margin-bottom: 5%;
}

.Rinfo form {
  width: 70%;
  margin-left: 10%;
}

.Rinfo form input {
  margin-bottom: 20px;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 2.5rem;
}

.eye-icon {
  position: absolute;
  top: 30%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.Register img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.already {
  margin-left: 10%;
}

@media only screen and (max-width: 480px) {
  .Register {
    display: block;
    margin-top: 25%;
    margin-bottom: 28%;
  }

  .Register img {
    display: none;
  }

  .Rinfo h1 {
    font-size: 25px;
  }
}

/* ---------------------------------------------homepage--------------------------------- */

.homepic {
  width: 100%;
  height: 81vh;
  color: #052F5F;
}
 .homepic h2{
  text-align: center;
  font-size: 50px;
  letter-spacing: 2px;
  font-family: sans-serif;
  font-weight: 700;
 }

 .home-sec-2{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(31%, 1fr));
  margin-right: 40px;
  margin-left: 40px;
  position: relative;
  height: 50%;
 }
 
 .home-sec-2::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 2px;
  background: linear-gradient(to left, transparent 0%, #052f5f 50%, transparent 100%);
 }
 .home-sec-2 img{
  width: 100%;
 }
 .home-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.home-about p{
  font-size: 25px;
  letter-spacing: 2px;
  font-family: sans-serif;
}

.home-icon{
  font-size: 100px;
}
.home-about button{
  height: 5vh;
  width: 25vh;
  float: right;
  margin-right: 15px;
  background-color: #5A1627;
  border: none;
  cursor: pointer;
}
.home-about button a{
  text-decoration: none;
  color: #fff;
  font-size: 15px;
}
.home-about button:hover{
  background-color: #904753;
}
.home-footer {
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-family: sans-serif;
  margin-top: 5%;
}

.home-footer h3 {
  font-size: 30px;
  font-family: sans-serif;
  letter-spacing: 2px;
  position: relative;
}
.home-footer .highlight {
  font-size: 20px;
}

.home-footer h3:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 0;
  right: -65px; 
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, #052f5f 50%, transparent 100%);
}

@media only screen and (max-width: 480px) {
  .homepic{
    height: 100%;
    width: 100%;
    margin-top: 5%;
  }
  .homepic h2{
    font-size: 25px;
    display: none;
  }
  .home-sec-2{
    display: block;
  }
  .home-sec-2::after{
    bottom: -25px;
    width: 100%;
  }
  .home-about p{
    font-size: 12.5px;
  }
  .home-icon{
    font-size: 50px;
  }
  .home-footer{
    display: grid;
  }
  .home-footer h3 {
    font-size: 15px;
  }
  .home-footer h3:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 2px;
    background: linear-gradient(to left, transparent 0%, #052f5f 50%, transparent 100%);
  }
}

/* --------------------------------------------About page------------------------------ */
.about{
  padding-bottom: 4%;
}
.profe{
  display: flex;
  align-items: center;
  position: relative;
}
.profe img{
  width: 60%;
  height: 70vh;
  object-fit: cover;
}

.profe-t{
  padding-top: 30px;
  height: 54vh;
  background-color:#5A1627;
  position: absolute;
  position: relative;
  left: -8%;
  box-sizing: border-box;
  width: 60vh;
  color: #fff;
}
.profe-t p{
  padding-left: 30px;
  line-height: 1.5;
  letter-spacing: 2px;
  font-family: sans-serif;
}
.profe-t h6 {
 padding-left: 30px;
}
.profe-t h1{
  padding-left: 30px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 30px;
}
.profe-t button{
  height: 5vh;
  width: 25vh;
  float: right;
  margin-right: 15px;
  background-color: #052f5f;;
  border: none;
  cursor: pointer;
}
.profe-t button a{
  text-decoration: none;
  color: white;
}
.profe-t button:hover{
  background-color: green;
}

.div-with-image {
  display: flex;
  align-items: center;
  padding-top: 5%;
  margin: 20px;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.text-container {
  width: 100%;
  margin-right: 20px;
  background-color: #052f5f;
  color: whitesmoke;
  height: 60.2vh;
}

.text-container p {
  margin-bottom: 10px;
  margin-left: 7%;
  line-height: 1.5;
  letter-spacing: 1px;
}
.text-container h1{
  text-align: center;
  color:#fff;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 30px;
}

@media (max-width: 768px) {
  .div-with-image{
    display: block;
  }
  .image-container img{
    width: 100%;
    height: 100%;
  }
  .text-container{
    height: 100%;
    padding-bottom: 20px;
  }
  .text-container p {
    margin-right: 5%;
    margin-bottom: 10px;
  }
  .image-container img{
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  
}

@media only screen and (max-width: 768px) {
    .profe {
    flex-direction: column;
    align-items: center;
    padding-top: 15%;
    }
    .profe img {
    width: 100%;
    height: auto;
    }
    .profe-t {
    position: static;
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    }

  }
  
  @media only screen and (max-width: 480px) {
  .profe {
    flex-direction: column;
    align-items: center;
    padding-top: 8%;
  }
  .profe-t h1 {
  font-size: 24px;
  }
  .profe-t h6 {
  font-size: 16px;
  }
  .profe-t p {
  font-size: 14px;
  }
  .aboutbtn {
  height: 30px;
  width: 120px;
  }
  }

  /* -----------------------------------------------Contact------------------------------------ */
  .contimg {
    height: 30vh;
    padding-top: 10%;
    text-align: center;
    align-items: center;
    width: 100%;
    background-color: #343a40;
  }
  .contact2 h1{
    font-size: 4rem;
    color: #fff;
  }

  .Cinfo{
    display: flex;
    justify-content: space-around;
    padding-bottom: 4%;
  }
  form {
    width: 50%;
  }
  label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
    color:#052f5f;
  }
  
  input[type="text"], input[type="email"], input[type="number"],  input[type="password"]{
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #052f5f;
  }
  textarea{
    width: 100%;
    padding: 10px;
    border: none;
    height: 12vh;
    border-bottom: 2px solid #052f5f;
  }
  
  input[type="submit"] {
    background:none;
    color: #5A1627;
    padding: 10px 30px;
    border: 2px solid #5A1627;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    width: 20vh;
    margin-top: 4%;
    border-radius: 20px;
    font-family: sans-serif;
  }
  
  input[type="submit"]:hover {
    background-color: #5A1627;
    color: #fff;
  }

  .contact-info{
    padding-top: 5%;
    align-items: center;
  }
  .contact-item{
    padding-bottom: 10%;
    display: flex;
    align-items: center;
  
  }
  .contact-icon{
     margin-right: 10px;
     font-size: 30px;
     color: #052f5f;
  }
  .contact-text{
    font-size: 20px;
    color: #052f5f;
    font-family: sans-serif;
    line-height: 1.5;
    letter-spacing: 2px;
  }
  .contact3 h2{
    text-align: center;
    padding-bottom: 3%;
    color: #052f5f;
    font-size: 35px;
    font-family: sans-serif;
  }
  .error{
    color: red;
  }
  
  @media screen and (max-width: 768px) { 
    .contimg {
    height: 30vh;
    padding-top: 20%;
    }
    .Cinfo{
      display: grid;
    }
    .contact-info{
      margin-top: 20%;
    }
    .contact2 h1 {
    font-size: 3rem;
    }
    form {
    width: 100%;
    }
    input[type="submit"] {
    width: 50%;
    }
    .contact3 h2{
        font-size: 20px;
    }
    }
    
    @media screen and (max-width: 480px) { 
    .contimg {
    height: 20vh;
    padding-top: 30%;
    }
    form {
      width: 100%;
     }
    .Cinfo{
      display: grid;
    }
    .contact2 h1 {
    font-size: 2rem;
    }
    .contact3 h2{
        margin-top: 18%;
    }
    input[type="submit"] {
    width: 70%;
    }
    }
    /* ---------------------------------------------------Skills-------------------------------- */
    .card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      padding-top: 7%;
    }
    
    .card {
      width: 30%;
      height: 200px;
      background-color: #fff;
      border-radius: 20px;
      display: flex;
      color: #052f5f;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 3%;
      transition: transform 0.3s ease;
      animation: cardAnimation 1s ease-in-out infinite alternate;
      box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    }
    
    .card:hover {
      transform: scale(1.05);
    }
    .card-button {
      width: 100px;
      height: 100px;
      border: 2px solid darkgray;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-66%);
      position: relative;
      overflow: hidden;
    }
    
    .card-button::before {
      content: '';
      position: absolute;
      bottom:  0;
      left: 0;
      width: 100%;
      height: var(--border-progress);
      background-color: #052f5f;
    }
    
    .card-button::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - var(--border-progress));
      background-color: #fff;
      animation: shakeWater 1s infinite;
    } 

    @keyframes shakeWater {
      0% {
        transform: translateX(-9px) rotate(-9deg);
      }
      50% {
        transform: translateX(9px) rotate(9deg);
      }
      100% {
        transform: translateX(-9px) rotate(-9deg);
      }
    }

    .card-icon {
      font-size: 50px;
      color: inherit;
      position: relative;
      z-index: 1;
    }

  @media screen and (max-width: 768px) { 
    .card-container {
      display: flex;
      padding-top: 10%;
    }
  }
  @media screen and (max-width: 480px) { 
    .card-container {
      display: block;
      padding-top: 30%;
      margin: 10px;
    }
    .card{
      margin-bottom: 20%;
      width: 100%;
    }
  }

 /* ----------------------------------------------------create blog-------------------------------- */
 .create-blog-page {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  height: 80vh;
}

.create-blog-page .form-group {
  margin-bottom: 20px;
  width: 120vh;
}

.create-blog-page label {
  display: block;
  margin-bottom: 5px;
}

.create-blog-page input[type='text'],
.create-blog-page input[type='file'] {
  width: 97%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-blog-page select {
  width: 99%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-blog-page .blogEditor {
  height: 20vh;
  z-index: 78;
  margin-bottom: 5%;
  width: 100%;
  overflow-y: auto; 
}

.create-blog-page button {
  background-color: green;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 25vh;
}

.create-blog-page button:hover {
  background-color: green;
}

@media screen and (max-width: 480px) {
  .create-blog-page {
    padding: 10px;
    padding-top: 20%;
  }
  .create-blog-page .form-group {
    width: 100%;
  }
  .blogEditor {
    padding-bottom: 15%;
  }
  .create-blog-page input[type='text'],
  .create-blog-page input[type='file'] {
    width: 94%;
  }
  .create-blog-page button {
    float: right;
  }
}

/* --------------------------------------get all blogs------------------------------------- */
.blogfooter{
  display: flex;
  justify-content: space-around;
}

.blogfooterx{
  display: flex;
}
.blogfooterx h4{
  padding-right: 20px;
  color:#727687;
  font: bold;
}
.blogside{
  width: 100%;
}
.btncategory{
  display: flex;
  text-align: center;
  justify-content: center;
}
.btncategory h4{
  margin-right: 10%;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: normal;
  color: #052f5f;
  font-family: Montserrat-Regular;
}

.btncategory .active{
  color: #5A1627;
}

.blog-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(31%, 1fr));
  grid-gap: 10px;
  justify-items: center;
  margin: 20px;
}

.blog {
  background-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 80%;
  width: 52vh;
  transition: transform 0.3s ease;
  animation: cardAnimation 1s ease-in-out infinite alternate;
  border-radius: 20px 20px 20px 20px;
}
.blog:hover {
  transform: scale(1.05);
}

.blog img {
  width: 100%;
  height: 60%;
  border-radius: 20px 20px 0px 0px;
}

.blog h2 {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
  color: #052f5f;
  font-family: sans-serif;
}

.blog p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.3;
  text-align: justify;
  padding: 0 20px ;
  color:#727687;
  font-family: sans-serif;
  max-height: 5em;
  overflow: hidden;
}

.blog a {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: #5A1627;
  text-decoration: none;
  display: flex ;
}
.icons{
  margin-left: 5px;
  padding-top: 2px;
}

.blog a:hover {
  color: #05E790;
}
.loading-circle {
  width: 40px;
  height: 40px;
  margin: auto;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media screen and (max-width: 1024px) {
  .blog-container {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    margin: 10px;
  }

  .blog{
      height: 90%;
  }

  .blog img {
    height: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .blog-container {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }
  .blog{
      width: 90%;
      height: 80vh;
  }

  .blog img {
    width: 100%;
    height: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .blog-container {
    grid-template-columns: repeat(auto-fit, minmax(80%, 1fr));
    margin: 10px;
    grid-gap: 20px;
  }
  .blog{
    height: 95%;
    margin-bottom: 10%;
  }
  .blog img {
    height: 50%;
  }
  .blogside{
    display: none;
  }
}
/* ----------------------------------single blog-------------------------------- */
.single-blog {
  width: 100%;
  margin: 0 auto;
}

.single-blog img {
  width: 60%;
  height: 70vh;
  object-fit: cover;
  margin-bottom: 10px;
  margin: 2% 10% 0 20%;
}

.single-blog h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  color: #052f5f;
  font-weight: bold;
}

.single-blog p {
  line-height: 1.5;
  margin: 0 10% 10px 10%;
  font-family: sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #727687;
}
@media only screen and (max-width: 480px) {
  .single-blog {
    width: 100%;
  }
  .single-blog img {
    width: 100%;
    height: auto;
    margin: 18% 2px 0 2px;
  }
  .single-blog h2{
    font-size: 30px;
  }
  .single-blog p {
    margin: 0 5% 0 5%;
  }
}
/* -------------------------------------------pagination--------------------------------------------------- */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination button {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  height: 5vh;
  width: 7vh;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
}

.pagination button:hover {
  background-color: #08509F;
  color: #fff;
}

.pagination button.active {
  background-color: #28a745;
  color: #fff;
}
/* --------------------------0--------------admin dashboard--------------------------------- */

.containerx {
  display: flex;
  height: 80vh;
}

.containerx .sidebar {
  background-color: #f1f1f1;
  width: 200px;
  display: flex;
  flex-direction: column;
}

.containerx .sidebar .active {
  color: rgb(14, 183, 81);
  background-color: #f1f1f1;
}

.containerx .sidebar h2 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20%;
  margin-bottom: 30%;
  font-size: 20px;
}

.containerx .sidebar .menu-btn {
  display: none;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 24px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}

.containerx .sidebar ul {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 17px;
  padding-left: 20px;
  font-weight: 600;
}

.containerx .sidebar ul li {
  margin-bottom: 20%;
}

.containerx .sidebar ul li a {
  text-decoration: none;
}

.containerx .content {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
}

.containerx .content .upper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3%;
}

.containerx .content .upper button {
  width: 20vh;
  height: 4vh;
  background-color: #ff9900;
  color: whitesmoke;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.containerx .content .upper button:hover {
  background-color: #d90;
}

.containerx .content .upper h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.containerx .content .table-wrapper .table {
  width: 100%;
  /* border-collapse: collapse; */
  margin-bottom: 20px;
  position: relative;
}

.containerx .content .table-wrapper .table td.error {
  text-align: center;
}

.containerx .content .table-wrapper .table th {
  padding: 10px 20px 10px 50px;
  text-align: start;
}

.containerx .content .table-wrapper .table td {
  padding: 10px;
  font-size: 14px;
}

.containerx .content .table-wrapper .table th {
  background-color: #121111;
  font-weight: bold;
  color: whitesmoke;
  font-size: 18px;
  justify-content: space-around;
}

.containerx .content .table-wrapper .table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.containerx .content .table-wrapper .table td.action button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 20px;
}

.containerx .content .table-wrapper .table td.action button:hover {
  background-color: #3e8e41;
}

.containerx .content .table-wrapper .table td.action button.delete {
  background-color: #ff0000;
}

.containerx .content .table-wrapper .table td.action button.delete:hover {
  background-color: #490b0b;
}

.containerx .content .table-wrapper .table td.action button.Disable {
  background-color: rgba(11, 11, 185, 0.876);
}

.containerx .content .table-wrapper .table td.action button.Disable:hover {
  background-color: rgb(27, 27, 233);
}

/* Mobile view styles */
@media (max-width: 768px) {
  .containerx {
    flex-direction: column;
    height: auto;
    margin-top: 30%;
  }

  .containerx .sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .containerx .sidebar .menu-btn {
    display: block;
    padding-bottom: 20px;
  }

  .containerx .sidebar ul.menu-list {
    display: none;
    width: 100%;
  }

  .containerx .sidebar ul.menu-list.show {
    display: block;
  }

  .containerx .sidebar h2 {
    margin-top: 10%;
    margin-bottom: 5%;
    font-size: 20px;
  }

  .containerx .sidebar ul li {
    margin-bottom: 6%;
  }

  .containerx .content {
    flex: 1;
  }

  .containerx .table thead tr {
    display: none;
  }

  .containerx .table,
  .containerx .table tbody,
  .containerx .table tr,
  .containerx .table td {
    display: block;
    width: 100%;
  }

  .containerx .table tr td {
    border: 1.2px solid #444;
  }

  .containerx .table tr {
    margin-bottom: 40px;
  }

  .containerx .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .containerx .table tbody tr::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    font-weight: bold;
    color: black;
    padding-left: 4px;
    text-align: left;
    font-size: 15px;
  }

  .containerx .content .upper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
  }

  .containerx .content .upper h2 {
    font-size: 20px;
    margin-bottom: 3%;
  }

  .containerx .content .upper button {
    width: 60%;
    height: 40px;
    margin-bottom: 5%;
  }
}

/* iPad View */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .containerx {
    flex-direction: column;
    margin-top: 15%;
  }

  .containerx .sidebar {
    margin-bottom: 20px;
    width: 100%;
  }

  .containerx .sidebar h2 {
    margin-top: 5%;
    margin-bottom: 50px;
    font-size: 30px;
  }

  .containerx .sidebar ul {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
  }

  .containerx .sidebar ul li {
    margin-bottom: 0;
  }

  .containerx .content {
    padding: 0 10px;
    overflow-x: scroll;
  }

  .containerx .content .table {
    width: auto;
    display: inline-table;
  }

  .containerx .content .table th,
  .containerx .content .table td {
    min-width: 120px;
  }

  .containerx .content .table td.action button {
    margin-right: 10px;
  }
}
